<template>
  <b-popover
    :id="`id_pop${popoverId}`"
    :target="`target${popoverId}`"
    :container="`target${popoverId}`"
    :style="scopedStyle"
    :placement="placement"
    ref="popover"
    triggers="hover focus"
  >
    <template #title>
      <div class="p-1">
        <div>
          <i class="pr-1 fa fa-bell"/>
          {{ title }}
        </div>
      </div>
    </template>
    <div>
      <div class="content">
        <div class="title">
          {{ booking.activityType === 'formula' ? $t('reserved-by') : $t('attenders') }}
        </div>
        <ul v-if="booking.participants.length > 0">
          <li
            v-for="(participant, i) in booking.participants"
            class="text"
          >
            <div
              v-if="notFormulasVisitor(participant)"
              :key="`participant - ${i}`"
            >
              {{ getParticipantName(participant) }}
            </div>
          </li>
        </ul>
        <div
          v-else
          class="text"
        >
          {{ $t('no_attenders') }}
        </div>
        <div
          v-if="booking.participants.length > 0"
          class="title"
        >
          {{ $t('count_attenders') }} :
          <span class="text">
            {{ booking.activityType === 'formula' ? booking.fixedParticipantsCount : booking.maxParticipantsCountLimit }}
          </span>
        </div>
        <div
          class="title mb-2"
        >
          {{ $t('duration') }} :
          <span
            class="text"
          >
            {{ duration }} {{ $t('minutes') }}
          </span>
        </div>
      </div>
      <div>
        <div>
          <div class="text d-inline-block">
            <b-avatar
              :class="currencyHeight"
              size="20px"
            >
              <i class="icofont icofont-cur-dollar"></i>
            </b-avatar>
            {{ price }}
          </div>
        </div>

        <div
          v-if="booking.comment != null"
          class="mt-1 text"
        >
          <b-avatar
            size="20px"
            icon="chat-text-fill"
            class="bck-gradient-red"
          />
          {{ booking.comment }}
        </div>
      </div>
    </div>
  </b-popover>
</template>

<script>
import {MINUTES} from "@/utils/date";
import {toFloatFixed} from "@/utils/string";
import {diffUtc, toTimeFormat, toTimezone} from "@/utils/timezone";
import {FORMULA_BOOKING_TYPE, LESSON_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";

export default {
  props: {
    popoverId: {
      type: String,
      default: 'popup_over'
    },
    booking: {
      type: Object,
      default: this,
    },
    playgroundPosition: {
      type: Number,
      default: 0,
    },
    params: {
      type: Object,
      default: this,
    },
    color: {
      type: String,
      default: '#2DC301',
    }
  },
  computed: {
    title() {
      let string = '';

      if (this.booking.category) {
        string = this.booking.category.name;
      } else if (this.booking.match) {
        string = this.$t('type_booking.match');
      } else if (this.booking.activityType === LESSON_BOOKING_TYPE) {
        string = this.$t('booking_type.label.lesson');
      } else if (this.booking.activityType === FORMULA_BOOKING_TYPE) {
        string = this.$t('booking_type.label.formula');
      } else {
        string = this.$t('type_booking.book');
      }

      return `${string} ${this.startAt}`;
    },
    price() {
      return `${toFloatFixed(this.booking.restToPay)} ${this.$currency} - ${this.$t('rest_to_pay')}`;
    },
    startAt() {
      return `- ${toTimeFormat(this.booking.startAt)}`;
    },
    currencyHeight() {
      return this.isPaid ? 'bck-success' : 'bck-gradient-warning ';
    },
    isPaid() {
      return null !== this.booking.consideredAsPaidAt || this.booking.restToPay <= 0;
    },
    placement() {
      if (this.params.orientation === 'vertical') {
        return 'right';
      } else {
        const timezone = this.$store.getters["auth/currentClub"].timezone;
        const hhFormat = this.params.timetable.startAt.split(':')[0];
        const mmFormat = this.params.timetable.startAt.split(':')[1];
        const slotDate = toTimezone(this.$store.getters["planning/getDate"]);

        slotDate.set({hour: hhFormat, minute: mmFormat, second: 0, millisecond: 0});
        slotDate.toISOString();
        slotDate.format();

        const bookingStartAt = this.$moment.utc(this.booking.startAt).tz(timezone);


        if (bookingStartAt.isSame(slotDate)) {
          if (this.playgroundPosition === 0) {
            return 'bottomright';
          }

          return 'right';
        } else {
          if (this.playgroundPosition === this.$store.getters["planning/getPlaygrounds"].length - 1) {
            return 'top';
          }
          if (this.playgroundPosition === 0) {
            return 'bottom';
          }

          return 'bottom';
        }
      }
    },
    scopedStyle() {
      return {
        '--booking-category': this.getColorBooking()
      }
    },
    duration() {
      return diffUtc(this.booking.endAt, this.booking.startAt, MINUTES);
    },
  },
  methods: {
    getParticipantName(participant) {
      if (participant.fullName) {
        return participant.fullName;
      } else if (participant.user) {
        return `${participant.user.firstName}  ${participant.user.lastName}`;
      } else if (participant.client) {
        return participant.client.fullName;
      } else {
        return this.$t('visitor');
      }
    },
    notFormulasVisitor (participant) {
      if (this.booking.activityType !== FORMULA_BOOKING_TYPE) {
        return !participant.canceled;
      } else {
        return true; // TODO MISSING CLIENT FIELD
      }
    },
    getColorBooking() {
      if (this.booking.category) {
        let color = null;
        this.params.bookingCategories.forEach(item => {
          if (item.bookingCategory['@id'] === this.booking.category['@id']) {
            color = item.color;
          }
        });
        if (color) {
          return color;
        }
      }
      if (this.booking.match !== null) {
        return this.params.colors.match;
      }
      switch (this.booking.creationOrigin) {
        case "white_label_app":
          return this.params.colors.booking_from_app;
        case "doinsport_app":
          return this.params.colors.booking_from_app;
        case "web_site":
          return this.params.colors.booking_from_web;
        case "administration":
          return this.params.colors.booking_from_club;
        case "automatic":
          return this.params.colors.recurring_booking;
        default:
          return '#2DC301';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .popover-header {
  font: normal normal 900 .8rem Avenir;
  letter-spacing: 0.4px;
  padding: 1px 0 0 0;
}

/deep/ .popover-header {
  color: #fff;
  background-color: var(--booking-category);
}

.popover {
  border: unset;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  min-width: 235px;
  max-width: 235px;
  max-height: fit-content;
}

.bck-success {
  background-color: #3db112 !important;
  color: #fff;
}

@import "@lazy/bookings/_booking-tooltip.scss";
</style>
